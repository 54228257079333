// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'http://localhost:4200',
  //apiUrl: 'https://ryzeapi.myteamconnector.com/public/',
  apiUrl: 'https://api.ryzelive.net/',
  STRIPEKEY:'pk_test_3iSaPoglwlUqTQ1E4qA8RvIM00JfCzq0cz',
  AWSKEY:'AKIAJP5DBU3DX53B32WQ',
  AWSSECRECT: '/mNj0dLKFhuSDOcylIlTl4jCmmropdcZZmGiXbvK',
  AESREGION: 'ap-south-1',
  AWSBUCKET: 'webkon-ecommerce',
  FOLDER: 'Ryzelive/ProfilePicture',
  agora: {
    appId: 'e7e517ea00254302943bc900ab4b64cc',
    rtmId: 'e7e517ea00254302943bc900ab4b64cc',
  },

  apiKey: "AIzaSyD-FfT1UQgPLunjpRVSb7xZwIbLXlgcuJg",
  authDomain: "ryzelive-1fa55.firebaseapp.com",
  databaseURL: "https://ryzelive-1fa55.firebaseio.com",
  projectId: "ryzelive-1fa55",
  storageBucket: "ryzelive-1fa55.appspot.com",
  messagingSenderId: "227368651193",
  appId: "1:227368651193:web:10ceb5c331cd04c35cce68",
  measurementId: "G-QFBX3BEVTZ",
  // apiKey: "AIzaSyA_1UtBm3szW4N5pG4phOToE-rPM3f4SN8",
  // authDomain: "ryzelive.firebaseapp.com",
  // databaseURL: "https://ryzelive.firebaseio.com",
  // projectId: "ryzelive",
  // storageBucket: "ryzelive.appspot.com",
  // messagingSenderId: "967060571565",
  // appId: "1:967060571565:web:8decd558ec4e0114698d24",


  firebase: {
    apiKey: "AIzaSyD-FfT1UQgPLunjpRVSb7xZwIbLXlgcuJg",
    authDomain: "ryzelive-1fa55.firebaseapp.com",
    databaseURL: "https://ryzelive-1fa55.firebaseio.com",
    projectId: "ryzelive-1fa55",
    storageBucket: "ryzelive-1fa55.appspot.com",
    messagingSenderId: "227368651193",
    appId: "1:227368651193:web:10ceb5c331cd04c35cce68",
    measurementId: "G-QFBX3BEVTZ"
  }
  // agora: {
  //   appId: 'cc6447cd14294abcb4e408583c653fac'
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
